<template>
  <PageLayout>
    <template #header>
      <div class="title">
        <h1>{{ $t('routes.orders') }}</h1>
        <div>
          <Button class="new-order-button" @click="createNewOrderProcess = true">{{ $t('orders.createOrder') }}</Button>
          <el-tooltip placement="top" :content="$t('orders.orderSettingsButton')">
            <Button
              type="secondary"
              class="p-1"
              @click="
                $router.push({
                  name: 'orderSettings',
                })
              "
              ><SettingsIcon
            /></Button>
          </el-tooltip>
        </div>
      </div>
    </template>
    <OrderProcesses
      :supplier-order-terms="allSupplierOrderTerms"
      :supplier-order-terms-loading="supplierOrderTermsLoading"
    />
    <NewOrderProcess
      :show="createNewOrderProcess"
      :supplier-order-terms="allSupplierOrderTerms"
      :supplier-order-terms-loading="supplierOrderTermsLoading"
      @close="createNewOrderProcess = false"
    />
  </PageLayout>
</template>

<script>
import { ref, computed } from 'vue';

import { useTenancy } from '@/modules/auth';
import { SettingsIcon } from '@/assets/icons';
import { PageLayout, Button } from '@/modules/core/components';

import { useSupplierOrderTerms } from '../purchase-management/components/orderTerms/compositions/useOrderTerms';
import OrderProcesses from './components/OrderProcesses';
import NewOrderProcess from './components/NewOrderProcess.vue';

const LIMIT = 100;
export default {
  components: {
    Button,
    PageLayout,
    SettingsIcon,
    OrderProcesses,
    NewOrderProcess,
  },
  setup() {
    const orderTermAfter = ref(0);
    const allSupplierOrderTerms = ref([]);
    const createNewOrderProcess = ref(false);

    const { currentTenant } = useTenancy();

    const { loading: supplierOrderTermsLoading, onResult: onOrderTermResult } = useSupplierOrderTerms(
      computed(() => ({
        businessId: currentTenant.value.id,
        first: LIMIT,
        after: orderTermAfter.value,
      }))
    );

    onOrderTermResult(
      ({
        data: {
          orderTerms: { nodes, totalCount },
        },
      }) => {
        const allCurrentResults = allSupplierOrderTerms.value || [];
        allCurrentResults.push(...nodes);
        allSupplierOrderTerms.value = allCurrentResults;
        if (nodes.length < totalCount) {
          orderTermAfter.value = LIMIT * orderTermAfter.value == 0 ? 1 : orderTermAfter.value;
        }
      }
    );

    return {
      allSupplierOrderTerms,
      supplierOrderTermsLoading,
      createNewOrderProcess,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .new-order-button {
    height: 2rem;
  }
}
</style>
