<template>
  <div>
    <TableLoadingSkeleton v-if="loading || supplierOrderTermsLoading" />
    <Table
      v-else
      border
      rounded
      :data="mutatedOpenOrders"
      :columns="columns"
      :show-index="currentIndex"
      :cell-class="handleCellClass"
      @row-click="onOrderRowClick"
    >
      <template
        #cell-supplier="{
          rowData: {
            supplier: { name: supplierName },
          },
        }"
      >
        <TruncatedText>
          {{ supplierName }}
        </TruncatedText>
      </template>
      <template #cell-productCount="{ rowData: { items } }">
        <p v-if="items.length > 0">{{ items.length }}</p>
        <p v-else class="grayedOut">{{ MISSING_DATA_TEXT }}</p>
      </template>
      <template
        #cell-deliveryArrivalTime="{
          rowData: {
            supplier: { id },
            arrivesAt,
          },
        }"
      >
        <DeliveryArrivalTimeCell :supplier-id="id" :arrives-at="arrivesAt" :all-terms="allTerms" />
      </template>
      <template
        #cell-orderCutOffTime="{
          rowData: {
            supplier: { id },
            isInThePast,
          },
        }"
      >
        <div v-if="isInThePast" class="d-flex gap-2 align-items-center">
          <p>{{ $t('orderProcessCart.cutOffTimePassed') }}</p>
          <WarningFullIcon :size="16" fill="#E52044" />
        </div>
        <OrderCutOffTimeCell v-else :supplier-id="id" :all-terms="allTerms" />
      </template>
      <template #cell-openedByUser="{ rowData: { openedByUser } }">
        <UserAvatarCell v-if="openedByUser?.profile" :user="openedByUser.profile" :token="token" />
        <p v-else>{{ MISSING_DATA_TEXT }}</p>
      </template>
      <template #cell-openedAt="{ rowData: { openedAt } }">
        <el-tooltip
          placement="top"
          :content="`${formatCurrentDateTimeWithWeekday(openedAt)}`"
          popper-class="date-tooltip"
        >
          <p class="date-with-time-content">{{ formatDuration(openedAt) }}</p>
        </el-tooltip>
      </template>
      <template #cell-sentStatus="{ rowData: { contentList, statusTagType, statusText } }">
        <el-tooltip v-if="contentList" placement="top" popper-class="date-tooltip">
          <div slot="content">
            <p v-for="(content, index) in contentList" :key="`sent-${index}`" class="sent-tooltip">
              <b>{{ content.channel }}</b
              >{{ content.separatorText }}{{ content.date ? formatCurrentDateTimeWithWeekday(content.date) : '' }}
            </p>
          </div>
          <Tag size="mini" :type="statusTagType">{{ statusText }}</Tag>
        </el-tooltip>
        <Tag v-else size="mini" :type="statusTagType">{{ statusText }}</Tag>
      </template>
      <template #cell-kebab="{ rowData, rowIndex }">
        <el-dropdown
          v-if="canInteractWithOrder(rowData)"
          class="d-flex justify-content-end ms-1"
          trigger="click"
          :placement="$direction === 'rtl' ? 'bottom-start' : 'bottom-end'"
          @command="(action) => handleAction(action, rowData)"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button type="icon" class="p-1 more-btn" @click.stop>
            <KebabIcon />
          </Button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="ORDER_ACTIONS.DELETE">
              <div class="delete-action">
                <TrashCanIcon />
                <p>{{ $t('orders.table.deleteActiveOrder.title') }}</p>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <ActionsModal
      v-if="isDeleteModalOpen"
      :toggle-dialog="isDeleteModalOpen"
      :title="$t('orders.table.deleteActiveOrder.title')"
      :content="$t('orders.table.deleteActiveOrder.modalDescription')"
      :confirm-button-text="$t('orders.table.deleteActiveOrder.delete')"
      :confirm-button-loading="deleteLoading"
      :cancel-button-text="$t('cancel')"
      :dialog-type="DIALOG_TYPES.DANGER"
      @on-cancel="onCloseDelete"
      @on-close="onCloseDelete"
      @on-confirm="deleteOrder"
    />
    <el-pagination
      class="my-2 float-right pb-4"
      layout="prev, pager, next, jumper"
      small
      hide-on-single-page
      background
      :page-size="limit"
      :total="total"
      :page-count="Math.ceil(total / limit)"
      @current-change="onPageChanged"
    />
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { useUser } from '@/modules/auth';
import { Table, TableLoadingSkeleton, TruncatedText, Button, ActionsModal, Tag } from '@/modules/core/components';
import { DIALOG_TYPES } from '@/modules/core/components/constants';
import { KebabIcon, TrashCanIcon, WarningFullIcon } from '@/assets/icons';
import { getActiveOrderProcessesColumns, ORDER_PROCESSES_HEADERS, getTranslation } from './orderProcessesColumns';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import {
  formatDate,
  formatCurrentDateTimeWithWeekday,
  formatDayAndTimeWithDate,
  formatDayAndTime,
  formatDuration,
  returnDashInsteadOfNull,
} from '../../purchase-management/purchaseManagementFormatters';
import {
  getClosestDeliveryArrivalDate,
  getClosestOrderTermCutOffDate,
} from '../../purchase-management/purchaseManagement';

import DeliveryArrivalTimeCell from './cellRenderers/DeliveryArrivalDateCell.vue';
import OrderCutOffTimeCell from './cellRenderers/OrderCutOffTimeCell.vue';
import UserAvatarCell from './UserAvatar/UserAvatar.vue';
import { useAnalytics } from '../../../analytics/compositions/useAnalytics';
import { PUR_ANALYTICS_EVENT_TYPES } from '@/analytics/types/pur-analytics-types';

const ORDER_ACTIONS = {
  DELETE: 'delete',
};

export const ORDER_STATUSES = {
  FAILED: 'failed',
  RETRY: 'retry',
  NOT_SENT: 'notSent',
  SENDING: 'sending',
  SENT_TO_CHANNEL: 'sentToChannel',
  PARTLY_SENT: 'partlySent',
  SENT: 'sent',
};

export default {
  name: 'ActiveOrderProcessesTable',
  components: {
    Table,
    TruncatedText,
    TableLoadingSkeleton,
    Tag,
    Button,
    KebabIcon,
    TrashCanIcon,
    ActionsModal,
    WarningFullIcon,
    UserAvatarCell,
    OrderCutOffTimeCell,
    DeliveryArrivalTimeCell,
  },
  props: {
    openOrders: { type: Array, required: true },
    supplierOrderTerms: { type: Array, required: true },
    supplierOrderTermsLoading: { type: Boolean, required: true },
    limit: { type: Number, required: true },
    page: { type: Number, required: true },
    total: { type: Number, required: true },
    loading: { type: Boolean, required: true },
    deleteLoading: { type: Boolean, required: true },
    isDeleteModalOpen: { type: Boolean, required: true },
  },
  emits: ['on-page-changed', 'on-delete-order', 'on-toggle-delete-modal'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const orderToDelete = ref();

    const currentIndex = computed(() => (props.page - 1) * props.limit + 1);
    const pageCount = computed(() => Math.ceil(props.total / props.limit));
    const allTerms = computed(() => props.supplierOrderTerms);
    const activeRowIndex = ref(-1);

    const { logEvent } = useAnalytics();
    const { token } = useUser();

    const onPageChanged = (page) => {
      emit('on-page-changed', { page });
    };

    const handleAction = (action, data) => {
      if (action === ORDER_ACTIONS.DELETE) {
        orderToDelete.value = data;
        emit('on-toggle-delete-modal', true);
      }
    };

    const onCloseDelete = () => {
      orderToDelete.value = null;
      emit('on-toggle-delete-modal', false);
    };

    const deleteOrder = () => {
      emit('on-delete-order', {
        orderIdToDelete: orderToDelete.value?.id,
        cartEmpty: orderToDelete.value?.items.length === 0,
      });
    };

    const getTagTypeAndTranslationByOrderStatus = (rowData) => {
      const tagAndTranslationKey = {
        tagType: '',
        text: '',
      };

      const dispatchesTotalStatuses = (rowData?.dispatches || []).reduce(
        (acc, { status }) => {
          switch (status) {
            case ORDER_STATUSES.SENT:
              acc.sentDispatches++;
              break;
            case ORDER_STATUSES.FAILED:
              acc.failedDispatches++;
              break;
            case ORDER_STATUSES.SENDING:
            case ORDER_STATUSES.RETRY:
              acc.sendingDispatches++;
              break;
            case ORDER_STATUSES.NOT_SENT:
            case ORDER_STATUSES.SENT_TO_CHANNEL:
              acc.notSentYet++;
              break;
            default:
              break;
          }

          ++acc.totalDispatches;
          return acc;
        },
        { totalDispatches: 0, sentDispatches: 0, failedDispatches: 0, sendingDispatches: 0, notSentYet: 0 }
      );

      const { totalDispatches, sentDispatches, failedDispatches, sendingDispatches, notSentYet } =
        dispatchesTotalStatuses;

      if (notSentYet === totalDispatches) {
        tagAndTranslationKey.tagType = 'neutral';
        tagAndTranslationKey.text = root.$t(`orders.sentStatus.${ORDER_STATUSES.NOT_SENT}`);
      } else if (failedDispatches === totalDispatches) {
        tagAndTranslationKey.tagType = 'danger';
        tagAndTranslationKey.text = root.$t(`orders.sentStatus.${ORDER_STATUSES.FAILED}`);
      } else if (sendingDispatches > 0) {
        tagAndTranslationKey.tagType = 'neutral';
        tagAndTranslationKey.text = root.$t(`orders.sentStatus.${ORDER_STATUSES.SENDING}`);
      } else {
        tagAndTranslationKey.tagType = 'danger';
        tagAndTranslationKey.text = root.$t(`orders.sentStatus.partlySent`, {
          sent: sentDispatches,
          total: totalDispatches,
        });
      }

      return tagAndTranslationKey;
    };

    const canInteractWithOrder = (rowData) => {
      if (rowData.sentStatus === ORDER_STATUSES.NOT_SENT) return true;
      return false;
    };

    const handleCellClass = (rowIndex) => {
      if (rowIndex === activeRowIndex.value) {
        return 'active-row';
      }
    };

    const actionsVisibleChange = (rowIndex, isVisible) => {
      activeRowIndex.value = isVisible ? rowIndex : -1;
    };

    const getStatusToolTip = (rowData) => {
      if (!rowData.dispatches) {
        return null;
      }
      const hasStatus = rowData.dispatches.every(({ status }) => status);
      if (!hasStatus) {
        return null;
      }
      let tooltipStrings = [];

      rowData.dispatches.forEach((dispatch) => {
        if (dispatch.status === ORDER_STATUSES.SENT) {
          tooltipStrings.push({
            channel: root.$t(`orderTerms.contactInfo.${dispatch.channel}`),
            separatorText: ` ${root.$t('orders.sentStatus.tooltip.sentAt')}`,
            date: dispatch.receivedAt,
          });
        }
        if (dispatch.status === ORDER_STATUSES.FAILED) {
          tooltipStrings.push({
            channel: root.$t(`orderTerms.contactInfo.${dispatch.channel}`),
            separatorText: ` ${root.$t('orders.sentStatus.tooltip.sendFailed')}`,
          });
        }
        if (
          dispatch.status === ORDER_STATUSES.SENDING ||
          dispatch.status === ORDER_STATUSES.RETRY ||
          dispatch.status === ORDER_STATUSES.SENT_TO_CHANNEL
        ) {
          tooltipStrings.push({
            channel: root.$t(`orderTerms.contactInfo.${dispatch.channel}`),
            separatorText: ` ${root.$t('orders.sentStatus.tooltip.sending')}`,
            date: rowData.dispatchedAt,
          });
        }
      });

      return tooltipStrings;
    };

    const mutatedOpenOrders = computed(() =>
      props.openOrders.map((rowData) => {
        const { tagType, text } = getTagTypeAndTranslationByOrderStatus(rowData);
        const closestArrivesAt = getClosestDeliveryArrivalDate(rowData.supplier.id, allTerms.value);
        const closestCutOffTime = getClosestOrderTermCutOffDate(rowData.supplier.id, allTerms.value);

        if (rowData.arrivesAt && !rowData.dispatches) {
          const arrivesAtDate = new Date(rowData.arrivesAt);
          const isArrivesAtDateInThePast =
            closestArrivesAt > arrivesAtDate &&
            closestArrivesAt.toISOString().split('.')[0] !== arrivesAtDate.toISOString().split('.')[0];

          if (isArrivesAtDateInThePast || closestCutOffTime > arrivesAtDate) {
            return {
              ...rowData,
              statusTagType: 'warning',
              statusText: root.$t('orders.sentStatus.datePassed'),
              isInThePast: true,
            };
          }
        }

        return {
          ...rowData,
          contentList: getStatusToolTip(rowData),
          statusTagType: tagType,
          statusText: text,
          isInThePast: false,
        };
      })
    );

    const onOrderRowClick = (rowIndex) => {
      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.ENTERED, {
        orderId: props.openOrders[rowIndex].id,
      });
      root.$router.push({
        name: 'orderItems',
        params: {
          orderId: props.openOrders[rowIndex].id,
        },
      });
    };

    return {
      DIALOG_TYPES,
      ORDER_ACTIONS,
      MISSING_DATA_TEXT,
      token,
      allTerms,
      mutatedOpenOrders,
      columns: getActiveOrderProcessesColumns(),
      openedAtText: ORDER_PROCESSES_HEADERS.OPENED_AT,
      currentIndex,
      pageCount,
      deleteOrder,
      handleAction,
      onPageChanged,
      onCloseDelete,
      getTranslation,
      handleCellClass,
      onOrderRowClick,
      getStatusToolTip,
      canInteractWithOrder,
      actionsVisibleChange,
      getTagTypeAndTranslationByOrderStatus,
      formatDuration: (date) => returnDashInsteadOfNull(formatDuration(date)),
      formatDayAndTime: (date) => returnDashInsteadOfNull(formatDayAndTime(date)),
      formatCurrentDateTimeWithWeekday: (date) =>
        returnDashInsteadOfNull(formatCurrentDateTimeWithWeekday(date, false)),
      formatDate: (date) => returnDashInsteadOfNull(formatDate(date)),
      formatDayAndTimeWithDate: (date) => returnDashInsteadOfNull(formatDayAndTimeWithDate(date)),
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.date-with-time-content,
.delivery-arrival,
.order-cutoff {
  width: fit-content;
}

.grayedOut {
  color: #9395a5;
}

tr {
  .more-btn {
    visibility: hidden;
  }
  .active-row {
    .more-btn {
      visibility: visible;
      background-color: #ecf0f3;
    }
  }
  &:hover {
    .more-btn {
      visibility: visible;
    }
  }
}
::v-deep {
  .active-row {
    background-color: #f8fafb;
  }
}
.delete-action {
  color: $checkbox-error;
  display: flex;
  height: 2rem;
  align-items: center;
  gap: 0.5rem;
}
.sent-tooltip {
  font-size: 0.75rem;
}
</style>
<style lang="scss">
.date-tooltip {
  &.el-tooltip__popper {
    max-width: 14rem;
    padding: 5px;
  }
}
</style>
