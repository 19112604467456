<template>
  <div>
    <TableLoadingSkeleton v-if="loading" />
    <Table
      v-else
      border
      rounded
      :data="closedOrders"
      :columns="columns"
      :show-index="currentIndex"
      @row-click="(rowIndex) => (chosenOrderIndex = rowIndex)"
    >
      <template
        #[`cell-${ORDER_PROCESSES_HEADERS.SUPPLIER}`]="{
          rowData: {
            supplier: { name },
          },
        }"
      >
        <TruncatedText>{{ name }}</TruncatedText>
      </template>
      <template #[`cell-${ORDER_PROCESSES_HEADERS.ORDER_ARRIVES_AT}`]="{ rowData: { arrivesAt } }">
        <p>
          {{ formatCurrentDateTimeWithWeekday(arrivesAt) }}
        </p>
      </template>
      <template #[`cell-${ORDER_PROCESSES_HEADERS.PRODUCT_COUNT}`]="{ rowData: { items } }">
        <p>{{ items.length }}</p>
      </template>
      <template #[`cell-${ORDER_PROCESSES_HEADERS.OPENED_BY_USER}`]="{ rowData: { openedByUser } }">
        <UserAvatarCell v-if="openedByUser?.profile" :user="openedByUser.profile" :token="token" />
        <p v-else>{{ MISSING_DATA_TEXT }}</p>
      </template>
      <template #[`cell-${ORDER_PROCESSES_HEADERS.OPENED_AT}`]="{ rowData: { openedAt } }">
        <el-tooltip
          placement="top"
          :content="`${getTranslation(openedAtText)}${formatCurrentDateTimeWithWeekday(openedAt)}`"
        >
          <p class="date-with-time-content">{{ formatDate(openedAt) }}</p>
        </el-tooltip>
      </template>
      <template #[`cell-${ORDER_PROCESSES_HEADERS.DISPATCHED_BY_USER}`]="{ rowData: { dispatchedByUser } }">
        <UserAvatarCell v-if="dispatchedByUser?.profile" :user="dispatchedByUser.profile" :token="token" />
        <p v-else>{{ MISSING_DATA_TEXT }}</p>
      </template>
      <template #[`cell-${ORDER_PROCESSES_HEADERS.SENT_AT}`]="{ rowData: { finalizedAt } }">
        <el-tooltip
          placement="top"
          :content="`${getTranslation(sentAtText)}${formatCurrentDateTimeWithWeekday(finalizedAt)}`"
        >
          <p class="date-with-time-content">{{ formatDate(finalizedAt) }}</p>
        </el-tooltip>
      </template>
    </Table>
    <ClosedOrderProcessesModal
      v-if="chosenOrderIndex !== -1"
      :order="closedOrders[chosenOrderIndex]"
      @on-cancel="chosenOrderIndex = -1"
    />
    <el-pagination
      class="my-2 float-right pb-4"
      layout="prev, pager, next, jumper"
      small
      hide-on-single-page
      background
      :page-size="limit"
      :total="total"
      :page-count="pageCount"
      @current-change="onPageChanged"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { Table, TableLoadingSkeleton, TruncatedText } from '@/modules/core/components';
import { getClosedOrderProcessesColumns, ORDER_PROCESSES_HEADERS, getTranslation } from './orderProcessesColumns';
import {
  formatDate,
  formatCurrentDateTimeWithWeekday,
  returnDashInsteadOfNull,
} from '../../purchase-management/purchaseManagementFormatters';
import ClosedOrderProcessesModal from './ClosedOrderProcessModal';
import UserAvatarCell from './UserAvatar/UserAvatar.vue';
import { useUser } from '@/modules/auth';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

export default {
  components: { Table, UserAvatarCell, TruncatedText, TableLoadingSkeleton, ClosedOrderProcessesModal },
  props: {
    closedOrders: { type: Array, required: true },
    limit: { type: Number, required: true },
    page: { type: Number, required: true },
    total: { type: Number, required: true },
    loading: { type: Boolean, required: true },
  },
  emits: ['on-page-changed'],
  setup(props, { emit }) {
    const onPageChanged = (page) => {
      emit('on-page-changed', { page });
    };
    const chosenOrderIndex = ref(-1);

    const currentIndex = computed(() => (props.page - 1) * props.limit + 1);
    const pageCount = computed(() => Math.ceil(props.total / props.limit));

    const { token } = useUser();

    return {
      MISSING_DATA_TEXT,
      ORDER_PROCESSES_HEADERS,
      token,
      columns: getClosedOrderProcessesColumns(),
      openedAtText: ORDER_PROCESSES_HEADERS.OPENED_AT,
      sentAtText: ORDER_PROCESSES_HEADERS.SENT_AT,
      pageCount,
      currentIndex,
      chosenOrderIndex,
      onPageChanged,
      getTranslation,
      formatCurrentDateTimeWithWeekday: (date) =>
        returnDashInsteadOfNull(formatCurrentDateTimeWithWeekday(date, false)),
      formatDate: (date) => returnDashInsteadOfNull(formatDate(date)),
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.date-with-time-content {
  width: fit-content;
}
</style>
