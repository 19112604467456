<template>
  <el-dialog
    class="closed-order-process-modal"
    :visible="true"
    custom-class="rounded closed-order-process-modal"
    :show-close="false"
    @close="onCancel"
  >
    <div slot="title" class="d-flex w-100 justify-content-end">
      <Button type="icon" class="p-0" @click="onCancel">
        <CloseIcon />
      </Button>
    </div>
    <div class="h-100">
      <FileViewer v-if="order.generatedPdfSignedUrl" :url="order.generatedPdfSignedUrl" />
    </div>
  </el-dialog>
</template>
<script>
import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { FileViewer } from '@clarityo/ui-components';

export default {
  name: 'ClosedOrderProcessesModal',
  components: { Button, FileViewer, CloseIcon },
  props: {
    order: { type: Object, required: true },
  },
  emits: ['on-cancel'],
  setup(_, { emit }) {
    const onCancel = () => {
      emit('on-cancel');
    };

    return {
      onCancel,
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';
$header-height: 79px; // Element header height
$footer-height: 62px; // Element footer height
::v-deep {
  .closed-order-process-modal {
    height: 90vh;
    width: 1216px;
    margin-top: 3rem !important;
    margin-bottom: 0rem !important;
  }
  .el-dialog__header {
    height: $header-height;
    border-radius: inherit;
    background: $white;
    margin-bottom: 1rem;
  }
  .el-dialog__body {
    height: calc(100% - calc($header-height + $footer-height));
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
